import React from "react";
import "./UserDashboardFaq.css";
import Accordion from "react-bootstrap/Accordion";
import { NavLink } from "react-router-dom";

const UserDashboardFaq = () => {
  const UserDashboardFaqData = [
    {
      id: 1,
      title: " Accordion Item 1",
      content:
        " This is the first item's accordion body.It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      id: 2,
      title: "Accordion Item 1",
      content:
        "This is the first item's accordion body.It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      id: 3,
      title: "Accordion Item 1",
      content:
        "This is the first item's accordion body.It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      id: 4,
      title: "Accordion Item 1",
      content:
        "This is the first item's accordion body.It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      id: 5,
      title: "Accordion Item 1",
      content:
        "This is the first item's accordion body.It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      id: 6,
      title: "Accordion Item 1",
      content:
        " This is the first item's accordion body.It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      id: 7,
      title: "Accordion Item 1",
      content:
        "This is the first item's accordion body.It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      id: 8,
      title: "Accordion Item 1",
      content:
        "This is the first item's accordion body.It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      id: 9,
      title: "Accordion Item 1",
      content:
        "This is the first item's accordion body.It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
    {
      id: 10,
      title: "Accordion Item 1",
      content:
        "This is the first item's accordion body.It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
    },
  ];

  return (
    <>
      <h1 className="faqheading">Frequently  Asked Questions</h1>
      <div className="user-dashboard-faq m-4">
        <Accordion defaultActiveKey="0">
          <div className="accordion accordionbox">
            {UserDashboardFaqData.map((item) => (
              <Accordion.Item eventKey={item.id}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>{item.content}</Accordion.Body>
              </Accordion.Item>
            ))}
          </div>
        </Accordion>
        <div className="faq-provider">
          <h3>
            <strong>Do you want to became an AFH Provider</strong>
          </h3>
          <NavLink to='/user/provider' className="button-next btn-success">
            Proceed
          </NavLink>
        </div>
        <div className="faq-provider mt-4">
          <h3>
            <strong>Do You Have Any Question</strong>
          </h3>
          <NavLink className="button-next btn-success">
            Contact Us
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default UserDashboardFaq;
