import React from "react";
import { Outlet } from "react-router-dom";

// import Navbar from './Navbar';

import Admin from "../Components/AdminPages/Admin/Admin";
import DashBoardNavBar from "./DashBoardNavBar/DashBoardNavBar";
// import Footer from '../Pages/Footer';
const WebsiteLayout = () => {
  return (
    <div>
      <div className="CommonComponent" style={{ overflow: "hidden" }}>
        <div className="">
          <DashBoardNavBar />
          <div className="row">
            <div className=" col-lg-2 col-4">
              <Admin />
            </div>
            <div className="col-lg-10">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  );
};

export default WebsiteLayout;
