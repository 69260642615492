import React from "react";

const Amenities = () => {
  const servies = [
    {
      key: "Activities coordinator",
    },
    {
      key: "Telephone",
    },
    {
      key: "Internet access",
    },
    {
      key: "Wheelchair accessible",
    },
    {
      key: "Beautician",
    },
    {
      key: "Library on Wheels",
    },
    {
      key: "Tv in room",
    },
    {
      key: "Hoyer Lift",
    },
    {
      key: "Emergency call buttons",
    },
    {
      key: "Outdoor Common Area",
    },
    {
      key: "Cable TV",
    },
    {
      key: "Newspaper",
    },
    {
      key: "Generator",
    },
   
    {
      key: "Games",
    },
    {
      key: "Arts and Crafts",
    },
    {
      key: "Birthday celebration",
    },
    {
      key: "Music",
    },
    {
      key: "Hospital Electric bed Available in the room",
    },
   
  ];
  return (
    <>
      <div style={{ padding: "20px" }}>
        <h1 className="mb-5">Activities & Amenities</h1>
        <div className="row">
          {servies.map((data) => (
            <div className="col-lg-3 mb-3">
              <div className="services-menu">
                <input value={data.key} type="checkbox" />
                <span style={{ marginLeft: "10px" }}>{data.key}</span>
              </div>
            </div>
          ))}
        </div>
        <div className="row mt-3">
          <div className="col-lg-6 col-md-6 col-12 text-end">
            <a href="" className="pre-btn">
              Pervious
            </a>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <a href="" className="pre-btn">
              Submit
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Amenities;
