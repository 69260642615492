import React from "react";
import UserDashborad from "./UserDashboard/UserDashborad";
import DashBoardNavBar from "./DashBoardNavBar/DashBoardNavBar";
import { Outlet } from "react-router-dom";

const UserWebsiteLayout = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <DashBoardNavBar />
          <div className="col-lg-2">
            <UserDashborad />
          </div>
          <div
            className="col-lg-10"
            style={{ backgroundColor: "#8080800d"
              , marginTop:'5rem'
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserWebsiteLayout;
