import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import "./SignIn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as API from "../../Components/util/url";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAuth } from "../../../src/Components/util/store/slices/authSlice";
import CommonModal from "../CommonModal/CommonModal";
import CommonModalFail from "../CommonModal/CommonModalFail";
import {
  faFacebookF,
  faTwitter,
  faGoogle,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setUserId } from "../../Components/util/store/wishlistSlice";
const SignIn = () => {
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verified, setVerified] = useState(false);
  const [signUpMode, setSignUpMode] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [message, setMessage] = useState({
    msg: "",
    urlmodaldata: "",
  });
  useEffect(() => {
    if (location.pathname === "/signup") {
      setSignUpMode(true);
    } else {
      setSignUpMode(false);
    }
  }, [location]);

  function onChange(value) {
    console.log("Captcha value:", value);
    setVerified(true);
  }

  const handleSignUpClick = () => {
    setSignUpMode(true);
  };

  const handleSignInClick = () => {
    setSignUpMode(false);
  };

  // Formik setup for Sign In
  const signInFormik = {
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("*email is required"),
      password: Yup.string().required("*Password is required"),
    }),
    // onSubmit: (values) => {
    //   console.log("Sign in values:", values);
    // },
  };

  // Formik setup for Sign Up
  const signUpFormik = {
    initialValues: {
      firstname: "",
      lastname: "",
      // phoneNumber: "",
      // email: "",
      // userType: "",
      // password: "",
      // confirmPassword: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("*First name is required"),
      lastname: Yup.string().required("*Last name is required"),
      phoneNumber: Yup.string().required("*Mobile number is required"),
      email: Yup.string()
        .email("*Invalid email address")
        .required("*Email is required"),
      // userType: Yup.string().required("*User type is required"),
      password: Yup.string().required("*Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    }),
    // onSubmit: (values) => {
    //   console.log("Sign up values:", values);
    // },
  };

  // signup api integration
  const onSubmitsignup = async (data) => {
    console.log(data);
    try {
      let response = await API.SignUp({
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        password: data.password,
        phoneNumber: data.phoneNumber,
      });
      if (response.data.status === true) {
        setMessage({ ...message, msg: response.data.message });
        // setShowSuccessModal(true);
        toast.success(response.data.message);
      } else {
        setMessage({ ...message, msg: response.data.status });
        // setShowFailureModal(true);
        toast.error(response.data.status);
      }
      console.log(response, "rsp");
      navigate("/signin");
    } catch (error) {
      console.log(error);
      toast.error("An error occurred during sign up.");
    }
  };
  // useEffect(() => {
  //   // onSubmitsignup();
  // }, []);
  // signin api integration
  const onSubmitsignin = async (data) => {
    console.log(data, ".....");
    try {
      let response = await API.SignIn({
        email: data.email,
        password: data.password,
      });
      console.log(response, "rsp");

      if (response.data.status === false) {
        setMessage({ ...message, msg: response.data.map.status });
        setShowFailureModal(true);
        toast.error(response.data.map.status);
      }

      // Check if response.data.map is defined and contains the expected properties
      if (response.data.map && response.data.map.message === "success") {
        localStorage.setItem(
          "afhnew",
          response.data.map.AccessToken
        );
        localStorage.setItem("email", response.data.map.email);
        localStorage.setItem("uId", response.data.map.uId);
        dispatch(
          setAuth({
            email: response.data.map.Name,
            AccessToken: response.data.map.AccessToken
          })
        );
        toast.success("Login successful!");
        navigate("/");
      } else {
        console.log(response.data.map.message);
        toast.error(response.data.map.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred during sign in.");
    }
  };

  // useEffect(() => {
  //   onSubmitsignin();
  // }, []);
  return (
    <div className={`sign-in-container ${signUpMode ? "sign-up-mode" : ""}`}>
      <div className="forms-container">
        <div className="signin-signup">
          {!signUpMode && (
            <Formik
              initialValues={signInFormik.initialValues}
              validationSchema={signInFormik.validationSchema}
              onSubmit={onSubmitsignin}
              validateOnChange={true}
              validateOnBlur={true}
            >
              <Form className="sign-in-form">
                <h2 className="title">Sign in</h2>
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <Field type="text" name="email" placeholder="Username" />
                </div>
                <ErrorMessage name="email" component="div" className="error" />
                <div className="input-field">
                  <i className="fas fa-lock"></i>
                  <Field
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
                <button type="submit" className="btns solid">
                  Login
                </button>
                <p className="social-text">Or Sign in with social platforms</p>
                <div className="social-media">
                  <a href="#" className="social-icon">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a href="#" className="social-icon">
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a href="#" className="social-icon">
                    <FontAwesomeIcon icon={faGoogle} />
                  </a>
                  <a href="#" className="social-icon">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                </div>
              </Form>
            </Formik>
          )}

          {signUpMode && (
            <Formik
              initialValues={signUpFormik.initialValues}
              validationSchema={signUpFormik.validationSchema}
              onSubmit={onSubmitsignup}
              validateOnChange={true}
              validateOnBlur={true}
            >
              <Form className="sign-up-form">
                <h2 className="title">Sign Up</h2>
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <Field
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                  />
                </div>
                <ErrorMessage
                  name="firstname"
                  component="div"
                  className="error"
                />
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <Field type="text" name="lastname" placeholder="Last Name" />
                </div>
                <ErrorMessage
                  name="lastname"
                  component="div"
                  className="error"
                />
                <div className="input-field">
                  <i className="fa-solid fa-phone"></i>
                  <Field
                    type="text"
                    name="phoneNumber"
                    placeholder="Mobile Number"
                  />
                </div>
                <ErrorMessage
                  name="phoneNumber"
                  component="div"
                  className="error"
                />
                <div className="input-field">
                  <i className="fa-solid fa-envelope-open"></i>
                  <Field type="email" name="email" placeholder="Email" />
                </div>
                <ErrorMessage name="email" component="div" className="error" />
                <div className="input-field">
                  <i className="fas fa-user"></i>
                  <Field as="select" name="userType">
                    <option value="lookingForCare">Looking for Care</option>
                    <option value="homeOwner">Home Owner</option>
                  </Field>
                </div>
                <div className="input-field">
                  <i className="fas fa-lock"></i>
                  <Field
                    type="password"
                    name="password"
                    placeholder="Password"
                  />
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error"
                />
                <div className="input-field">
                  <i className="fas fa-lock"></i>
                  <Field
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                  />
                </div>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="error"
                />
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={onChange}
                />
                <button type="submit" className="btns">
                  Sign up
                </button>
                {/* <p className="social-text">Or  with social platforms</p> */}
              </Form>
            </Formik>
          )}
        </div>
      </div>

      <div className="panels-container">
        <div className={`panel left-panel ${signUpMode ? "hide" : ""}`}>
          <div className="content">
            <h3>New to our community?</h3>
            <p>
              Discover a world of possibilities! Join us and explore a vibrant
              community where ideas flourish and connections thrive.
            </p>
            <button
              className="btns transparent btnresp"
              onClick={handleSignUpClick}
            >
              Sign up
            </button>
          </div>
          <img
            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1722248241/Privacy-policy-rafikinew_xthgqi.png"
            className="image"
            alt="Sign up illustration"
          />
        </div>

        <div className={`panel right-panel ${!signUpMode ? "hide" : ""}`}>
          <div className="content">
            <h3>One of Our Valued Members</h3>
            <p>
              Thank you for being part of our community. Your presence enriches
              our shared experiences. Let's continue this journey together!
            </p>
            <button className="btns transparent" onClick={handleSignInClick}>
              Sign in
            </button>
          </div>
          <img
            src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1722082495/Mobile-login-rafikinew_v9ho2f.png"
            className="image"
            alt="Sign in illustration"
          />
        </div>
      </div>
      <CommonModal
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        message={message}
      />
      <CommonModalFail
        show={showFailureModal}
        onHide={() => setShowFailureModal(false)}
        message={message}
      />
      <ToastContainer position="top-right" autoClose={5000} theme="colored" />
    </div>
  );
};

export default SignIn;
