import React, { useState } from "react";
import "./HomeDescription.css";

const HomeDescription = () => {
  const [fileNames, setFileNames] = useState({
    thumbnail: "",
    moreImages: "",
    video: "",
  });

  const [filePreviews, setFilePreviews] = useState({
    thumbnail: "",
    moreImages: "",
    video: "",
  });

  const handleFileInputClick = (inputId) => {
    document.getElementById(inputId).click();
  };

  const handleFileInputChange = (event, key) => {
    const file = event.target.files[0];
    if (file) {
      // Update file name
      setFileNames({
        ...fileNames,
        [key]: file.name,
      });

      // Generate preview URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreviews({
          ...filePreviews,
          [key]: reader.result,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setFileNames({
        ...fileNames,
        [key]: "",
      });
      setFilePreviews({
        ...filePreviews,
        [key]: "",
      });
    }
  };


  return (
    <div style={{ padding: "20px" }}>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-12">
          <div className="websiteUrl">
            <label htmlFor="website-url">
              Website Url <i className="fa-solid fa-circle-info"></i>
            </label>
            <input
              type="url"
              id="website-url"
              placeholder="Enter your URL"
              className="mt-4"
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="websiteUrl">
            <label htmlFor="thumbnail-upload">
              Upload Thumbnail for Your Home
            </label>
            <div className="file-input-wrapper mt-4">
              <div className="form-group">
                <input
                  id="thumbnail-upload"
                  type="file"
                  name="thumbnail"
                  className="input-file"
                  accept="image/*"
                  onChange={(e) => handleFileInputChange(e, "thumbnail")}
                  style={{ display: "none" }} // Hide the default file input
                />
                <div className="input-group">
                  <span className="input-group-addon"></span>
                  <input
                    type="text"
                    className="form-control"
                    value={fileNames.thumbnail}
                    disabled
                    placeholder="Upload image"
                  />
                  <span className="input-group-btn">
                    <button
                      className="upload-field btn"
                      type="button"
                      onClick={() => handleFileInputClick("thumbnail-upload")}
                    >
                      <i className="fa fa-search"></i> Browse
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="websiteUrl">
            <label htmlFor="more-images-upload">Upload More Images</label>
            <div className="file-input-wrapper mt-4">
              <div className="form-group">
                <input
                  id="more-images-upload"
                  type="file"
                  name="more-images"
                  className="input-file"
                  accept="image/*"
                  onChange={(e) => handleFileInputChange(e, "moreImages")}
                  style={{ display: "none" }} // Hide the default file input
                />
                <div className="input-group">
                  <span className="input-group-addon"></span>
                  <input
                    type="text"
                    className="form-control"
                    value={fileNames.moreImages}
                    disabled
                    placeholder="Upload image"
                  />
                  <span className="input-group-btn">
                    <button
                      className="upload-field btn"
                      type="button"
                      onClick={() => handleFileInputClick("more-images-upload")}
                    >
                      <i className="fa fa-search"></i> Browse
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-12">
          <div className="websiteUrl">
            <label htmlFor="video-upload">Upload Your Home Video</label>
            <div className="file-input-wrapper mt-4">
              <div className="form-group">
                <input
                  id="video-upload"
                  type="file"
                  name="video"
                  className="input-file"
                  accept="video/*"
                  onChange={(e) => handleFileInputChange(e, "video")}
                  style={{ display: "none" }} // Hide the default file input
                />
                <div className="input-group">
                  <span className="input-group-addon"></span>
                  <input
                    type="text"
                    className="form-control"
                    value={fileNames.video}
                    disabled
                    placeholder="Upload video"
                  />
                  <span className="input-group-btn">
                    <button
                      className="upload-field btn"
                      type="button"
                      onClick={() => handleFileInputClick("video-upload")}
                    >
                      <i className="fa fa-search"></i> Browse
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="detail-description">
            <label htmlFor="home-description">
              Write Detailed Description of Your Home
            </label>
            <br />
            <textarea id="home-description" className="mt-2" cols={5} rows={5}></textarea>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-6 col-md-6 col-12 text-end">
          <a href="#" className="pre-btn">
            Previous
          </a>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <a href="#" className="pre-btn">
            Submit
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeDescription;
