import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./UserDashboard.css";

const UserDashboard = () => {
  const [isNavToggled, setIsNavToggled] = useState(false);

  const handleNavToggle = () => {
    setIsNavToggled(!isNavToggled);
  };

  return (
    <div className={isNavToggled ? "nav-is-toggled" : "nav-is-default"}>
      <aside className="nav-side">
        <button
          type="button"
          className="nav-toggle"
          id="navSideToggle"
          onClick={handleNavToggle}
        >
          {/* <span className="sr-only">Menu</span>{" "} */}
          <span className="ham-bars"></span>
          <div style={{ color: "black", paddingLeft: "48px" }}>
            <img src=""></img>
          </div>
        </button>
        <ul className="nav-side-links">
          <li className="nav-side-item">
            <NavLink className="nav-link active" to="#">
              <span className="icon material-icons">dashboard</span>{" "}
              <span className="link-text">Dashboard</span>
            </NavLink>
          </li>
          <li className="nav-side-item">
            <NavLink className="nav-link" to="#">
              <span className="icon material-icons">favorite</span>{" "}
              <span className="link-text">Favourites</span>
            </NavLink>
          </li>
          <li className="nav-side-item">
            <NavLink className="nav-link" to="#">
              {/* <span className="icon material-icons">favorite</span>{" "} */}
              <i class="fa-solid fa-car"></i>
              <span className="link-text" style={{ marginLeft: "20px" }}>
                Tour Request
              </span>
            </NavLink>
          </li>
          <li className="nav-side-item">
            <div className="accordion accordiondiv" id="accordionExample">
              <div className="accordion-item accordion-back">
                <h2 className="accordion-header accord-head" id="headingOne">
                  <button
                    className="accordion-button accordbtn collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                    // style={{padding:'15px -2px'}}
                  >
                    <span className="icon material-icons">help</span>{" "}
                    <span className="link-text" style={{ marginLeft: "18px" }}>
                      Help
                    </span>
                    <i
                      className="fa-solid fa-chevron-down"
                      style={{ marginLeft: "15px" }}
                    ></i>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="">
                    <ul className="lead-dropdown">
                      <li className="nav-side-item">
                        <NavLink className="nav-link" to="/user/faq">
                          <span className="link-text">FAQs</span>
                        </NavLink>
                      </li>
                      <li className="nav-side-item">
                        <NavLink className="nav-link" to="/user/home-owner">
                          <span className="link-text">
                            Are You a Homeowner?
                          </span>
                        </NavLink>
                      </li>
                      <li className="nav-side-item">
                        <NavLink className="nav-link" to="/contact">
                          <span className="link-text">Contact</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default UserDashboard;
