import React from "react";
import "./OwnerListedHomes.css";
import { Link } from "react-router-dom";

const OwnerListedHomes = () => {
  const listHomes = [
    {
      id: 1,
      img: "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1566825562/afh/blog/images/fib5jmzr0kh9dkbpul8c.jpg",
      ownerName: "Mugdha Bhoot",
      homeAddress:
        "Cecilia Chapman 711-2880 Nulla St. Mankato Mississippi 96522 (257) 563-7401",
    },
    {
      id: 2,
      img: "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1566825562/afh/blog/images/fib5jmzr0kh9dkbpul8c.jpg",
      ownerName: "Mugdha Bhoot",
      homeAddress:
        "Cecilia Chapman 711-2880 Nulla St. Mankato Mississippi 96522 (257) 563-7401",
    },
    {
      id: 3,
      img: "https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1566825562/afh/blog/images/fib5jmzr0kh9dkbpul8c.jpg",
      ownerName: "Mugdha Bhoot",
      homeAddress:
        "Cecilia Chapman 711-2880 Nulla St. Mankato Mississippi 96522 (257) 563-7401",
    },
  ];

  return (
    <div className="container">
      <div className="row mt-4">
        {listHomes.map((home) => (
          <div className="col-lg-4 col-md-6 col-12" key={home.id}>
            <div className="listed-homes mb-4">
              <img src={home.img} alt="listed-homes-img" />
              <span>{home.ownerName}</span>
              <p>{home.homeAddress}</p>
              <Link to="/list-home-LandingPage" className="btn-list-home">
                View More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OwnerListedHomes;
