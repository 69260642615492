import React from "react";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Footer from '../../Pages/Footer';
import * as API from "../../Components/util/url";
import "./AddReviews.css";

const validationSchema = Yup.object({
  title: Yup.string().required("Please provide a Title for your Review"),
  review: Yup.string()
    // .min(200, "Review must be at least 200 characters")
    .required("Please enter your Review"),
  connection: Yup.string().required("Please enter your connection"),
  overall: Yup.number()
    .min(1, "Please provide a rating value for all criteria.")
    .required("Please provide a rating value for all criteria."),
});

const AddReviews = () => {
  const location = useLocation();
  const stayId = location.state?.stayId; 
  const pgName=location.state?.pgName;

  console.log("stayId reviews:", stayId);
  console.log("city reviews:", pgName);

  const initialValues = {
    title: "",
    review: "",
    connection: "",
    overall: 0,
  };

  const addReviews = async (data) => {
    if (!stayId) {
      console.error("stayId is undefined or null");
      return;
    }
    try {
      const response = await API.addReview({
        content: data.review,
        title:data.title,
        homeId: stayId,
        connection: data.connection,
        stars: data.overall,
      });
      console.log(response, 'reviews');
    } catch (error) {
      console.error("Error adding review:", error);
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    addReviews(values);
    resetForm();  
  };

  return (
    <div>
      <div className="brand-img">
        <img
          src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720704397/afhlogo_zfiv9i.png"
          alt="dashboard-logo"
          width={150}
        />
      </div>

      <div className="container">
        <div className="review-heading">
          Write a Review for {pgName}
        </div>
        <div className="mt-2 review-insights-experiences">
          Please share your insights and experiences in as much detail as
          possible to help others learn more about Coquina Crossing-St
          Augustine.
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched }) => (
            <Form>
              <div className="all-font">
                How would you rate your overall experience with this facility?
              </div>
              <div
                className={`overall ${
                  touched.overall && errors.overall
                    ? "rating-container error"
                    : "rating-container"
                }`}
              >
                <div className="overall-exp">Overall Experience</div>
                <div className="rating">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <span
                      key={star}
                      className={`star ${
                        values.overall >= star ? "filled" : ""
                      }`}
                      onClick={() =>
                        setFieldValue(
                          "overall",
                          values.overall === star ? 0 : star
                        )
                      }
                    >
                      &#9733;
                    </span>
                  ))}
                </div>
              </div>
              <ErrorMessage
                name="overall"
                component="div"
                className="field-error"
              />

              <div className="mb-3">
                <div className="title-label">Title of your review</div>
                <Field
                  className="title-input"
                  name="title"
                  type="text"
                  placeholder="Summarize your experience in a few words"
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="field-error"
                />
              </div>

              <div className="mb-3">
                <div className="title-label">Your review</div>
                <div className="minimum-character">
                  A<span className="fw-bold"> minimum of 200 characters</span>{" "}
                  and max of 1,000 characters required.
                </div>
                <div className="text-area-data">
                  <Field
                    as="textarea"
                    name="review"
                    rows={12}
                    className="text-area mt-3"
                    placeholder="Tell others about your experience"
                  />
                  {/* <div className="text-area-required">
                    <p className="mb-2">
                      Currently {values.review.length} characters.
                    </p>
                    Review comments must comply with our
                    <span className="text-area-required-color">
                      Reviewer Guidelines
                    </span>
                    .
                  </div> */}
                </div>
                <ErrorMessage
                  name="review"
                  component="div"
                  className="field-error"
                />
              </div>

              <div>
                <div className="all-font">
                  What is your connection to this facility?
                </div>
                <Field
  as="select"
  name="connection"
  className="title-input mt-3"
>
  <option value="">Select...</option>
  <option value="I'm a current/former resident client">I'm a current/former resident client</option>
  <option value="I toured this community">I toured this community</option>
  <option value="I'm a loved one of a resident client">I'm a loved one of a resident client</option>
  <option value="I work in the industry">I work in the industry</option>
  <option value="Others">Others</option>
</Field>

                <ErrorMessage
                  name="connection"
                  component="div"
                  className="field-error"
                />
              </div>

              <div className="mt-4 term-check">
                <Field
                  type="checkbox"
                  name="agreeTerms"
                  className="tick-checkbox"
                />
                <div className="ms-2 terms-agree">
                  I certify this is my genuine opinion and in submitting it I
                  agree to the Terms of Use.
                </div>
              </div>

              <div className="mt-4">
                <button type="submit" className="btns">
                  Submit Review
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Footer />
    </div>
  );
};

export default AddReviews;
