import React from "react";
import DashBoardNavBar from "./DashBoardNavBar/DashBoardNavBar";
import { Outlet } from "react-router-dom";
import OwnerDashboard from "./OwnerDashboard/OwnerDashboard";
import OwnerSideNav from "../Components/OwnerSideNav/OwnerSideNav";

const OwnerWebsiteLayout = () => {
  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <DashBoardNavBar />
          <div className="col-lg-2" style={{ padding: "0px" }}>
            {/* <OwnerDashboard /> */}
            <OwnerSideNav />
          </div>
          <div className="col-lg-10" style={{ backgroundColor: "#8080800d",marginTop:'6rem' }}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerWebsiteLayout;
