import React from "react";
import "./OwnerAFHServices.css";

const OwnerAFHServices = () => {
  return (
    <>
      <div style={{ backgroundColor: "#efefef", height: "41rem" }}>
        <div className="afh-services ">
          <h2>AFH Services</h2>
        </div>
        <div className="services-names">
          <span>Name</span>
          <span>Company Name</span>
          <span>Services</span>
          <span>Phone Number</span>
        </div>
        <div className="services-list">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-6 col-12">
                <ul className="names">
                  <li>Dr.Addison</li>
                  <li>Lois Filipelli</li>
                  <li>Larissa Satichtchev</li>
                  <li>Dr.Gorey</li>
                  <li>Salve</li>
                </ul>
              </div>
              <div className="col-lg-3 col-6 col-12">
                <ul className="company-names">
                  <li>Northwest Geriatrics</li>
                  <li>Modern Optometry</li>
                  <li>-</li>
                  <li>-</li>
                  <li>-</li>
                </ul>
              </div>
              <div className="col-lg-3 col-6 col-12">
                <ul className="services">
                  <li>in home - Doctor</li>
                  <li>in home - Optometrist</li>
                  <li>Music Therapy</li>
                  <li>Foot care</li>
                  <li>Games and Activities</li>
                </ul>
              </div>
              <div className="col-lg-3 col-6 col-12">
                <ul className="phone-no">
                  <li>206-275-3588</li>
                  <li>360-524-4205</li>
                  <li>425-373-5450</li>
                  <li>253-370-6129</li>
                  <li>206-313-6697</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerAFHServices;
