import React from "react";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";
import { Link, NavLink } from "react-router-dom";
import "./Medicaidpage.css";

const Medicaidpage = () => {
  return (
    <div>
      <div className="families">
        <CommonNavbar />
        <div className="container mt-5 ">
          <div className="row">
            <div className="familybanner">
              <h1>Medicaid</h1>
              <p>
                Medicaid is a government health insurance program available to
                people with very limited income and resources. Medicaid does not
                pay money to you. It sends payments directly to your health care
                providers. Medicaid can pay for medical services in your own
                home or if you live in a residential care facility that takes
                Medicaid residents.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="medicaid-sect1">
        <div className="container">
          <div className="row">
            <div className="stars mt-5">
              <span className="fa fa-star checked"> </span>
              <span>
                Find an{" "}
                <Link
                  to="https://fortress.wa.gov/dshs/adsaapps/lookup/AFHPubLookup.aspx"
                  target="__blank"
                  className="medicaidlink"
                >
                  Adult Family Home in Washington State
                </Link>
              </span>
            </div>
            <div className="medicaid-content">
              <h1>Medicaid and Adult Family Homes</h1>
              <p>
                Adult Family Homes are regular neighborhood homes where staff
                assumes responsibility for the safety and well-being of the
                adult. A room, meals, laundry, supervision and varying levels of
                assistance with care are provided. Some provide occasional
                nursing care. Some offer specialized care for people with mental
                health issues, developmental disabilities or dementia. The home
                can have two to eight residents and is licensed by the state.
              </p>

              <p>
                Medicaid will pay for care only when someone becomes financially
                eligible. There are many Adult Family Homes (AFH's) in
                Washington State that accept Medicaid. There are some AFH's that
                will accept a resident upon admission that has Medicaid as a
                form of payment but these homes may be full as the need is high.
                The majority of AFH's will require a new resident to pay
                privately for a set amount of time before transitioning the
                resident to medicaid. This is referred to as medicaid spend
                down. The time required for private pay can range from 6 months
                to 3+ years or until all the residents' funds are exhausted and
                the resident becomes "financially eligible" for medicaid.
              </p>

              <p>
                What sets an Adult Family Home apart from Assisted Living is
                that AFH offer a transition to medicaid. Many Assisted Livings
                do not accept medicaid as a form of payment. It is very
                beneficial for a Senior to enter an Adult Family Home knowing
                that they will stay in the same place even when their hard
                earned funds are used up. Washington State has set guidelines
                for all Long Term Facilities that accept medicaid to follow.
                Refer to WAC 388-76-10522.
              </p>

              <h6>WAC 388-76-10522</h6>
              <h6>
                Resident rights-Notice-Policy on accepting Medicaid as a payment
                source.
              </h6>
              <p>
                The adult family home must fully disclose the home's policy on
                accepting Medicaid payments. The policy must:
              </p>
              <ol>
                <li>
                  Clearly state the circumstances under which the adult family
                  home provides care for Medicaid eligible residents and for
                  residents who become eligible for Medicaid after admission;
                </li>

                <li>
                  Be provided both orally and in writing in a language that the
                  resident understands;
                </li>

                <li>
                  Be provided to prospective residents, before they are admitted
                  to the home;
                </li>

                <li>
                  Be provided to any current residents who were admitted before
                  this requirement took effect or who did not receive copies
                  prior to admission;
                </li>

                <li>
                  Be written on a page that is separate from other documents and
                  be written in a type font that is at least fourteen point; and
                </li>

                <li>
                  Be signed and dated by the resident and be kept in the
                  resident record after signature.
                </li>
              </ol>
              <p>
                To explore an adult family home as an option, find out what
                kinds of services and supports are available at each home you
                are interested in. For more information about the types of
                questions to ask and things to look for when finding an adult
                family home, see our brochure:
                <Link
                  className="medicaidlink"
                  to="https://www.dshs.wa.gov/sites/default/files/publications/documents/22-707.pdf"
                  target="__blank"
                >
                  {" "}
                  Choosing Care in an Adult Family Home or Assisted Living
                  Facility.
                </Link>
              </p>
            </div>
            {/* how to apply */}
            <div className="howtoapply mt-2">
              <div className="row">
                <div className="col-lg-9 col-md-6 col-12 ordertwo howtoapply-medicaid">
                  <h2>How to Apply for Medicaid</h2>
                  <p>
                    If you are 18 or older, you can apply for Medicaid through
                    your local Home and Community Services (HCS) office or
                    online.
                  </p>
                  <p>There are three main parts to the application process:</p>
                  <ol>
                    <li>Filling out and turning in an application.</li>
                    <li>
                      A financial review to determine your financial
                      eligibility.
                    </li>
                    <li>
                      A personal care needs assessment to determine your
                      functional eligibility.
                    </li>
                  </ol>
                </div>
                <div className="col-lg-3 col-md-6 col-12 orderOne">
                  <div className="medicaid-sec3 ">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720096619/section4-afh_ut8enj.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="medicaid-sec3">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720703692/providerimg2_rd1931.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-9 col-md-6 col-12 apply-person-mail">
                  <h2 className="mt-3">Apply in person or by mail</h2>
                  <p>
                    Call or visit{" "}
                    <Link
                      to="https://www.dshs.wa.gov/ALTSA/resources"
                      className="medicaidlink"
                      target="__blank"
                    >
                      {" "}
                      your local HCS office
                    </Link>
                    to get a Washington Apple Health Application for Long-Term
                    Care/Aged, Blind, Disabled Coverage (18-005).
                  </p>
                  <p>
                    This form can also be downloaded from the
                    <Link
                      className="medicaidlink"
                      to="https://www.hca.wa.gov/free-or-low-cost-health-care/forms-and-publications"
                      target="__blank"
                    >
                      Health Care Authority{" "}
                    </Link>
                    website
                  </p>
                  <ul>
                    <li>
                      Washington Apple Health Application for Long-term
                      Care/Aged, Blind, Disabled Coverage Form (Form 18-005)
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-9 col-md-6 col-12 apply-online">
                  <h2>Apply on-line</h2>
                  <p>
                    To apply online for cash, basic food benefits and long-term
                    care, you may go to{" "}
                    <Link
                      className="medicaidlink"
                      to="https://www.washingtonconnection.org/home/"
                      target="__blank"
                    >
                      Washington Connections
                    </Link>{" "}
                    .
                  </p>
                  <p>
                    All Washington residents can apply for Health Care Coverage
                    including institutional Medicaid through the{" "}
                    <Link
                      className="medicaidlink"
                      to="https://maint.wahealthplanfinder.org/"
                      target="__blank"
                    >
                      wahealthplanfinder.org
                    </Link>
                  </p>
                  <p>
                    For long-term care services you may apply online through the
                    health plan finder. Indicate you are applying for long term
                    care services.
                  </p>
                  <p>
                    The Statewide Health Insurance Benefits Advisors (SHIBA)
                    HelpLine provides free help to people of all ages with
                    questions about health insurance, health care access, and
                    prescription access. Contact the{" "}
                    <Link
                      className="medicaidlink"
                      to="https://www.insurance.wa.gov/statewide-health-insurance-benefits-advisors-shiba"
                      target="__blank"
                    >
                      SHIBA HelpLine
                    </Link>{" "}
                    .
                  </p>

                  <h3>Information you will need</h3>
                  <p>Information you will need to provide includes:</p>
                  <ul>
                    <li>A Social Security number.</li>
                    <li>Proof of identification.</li>
                    <li>Proof of income.</li>
                    <li>
                      Documentation of resources (such as bank statements,
                      property tax statements, life insurance)
                    </li>
                    <li>Immigration or alien documents.</li>
                    <li>
                      Proof of citizenship if you do not receive{" "}
                      <Link
                        className="medicaidlink"
                        to="https://www.dshs.wa.gov/altsa/home-and-community-services/medicare"
                        target="__blank"
                      >
                        {" "}
                        Medicare
                      </Link>{" "}
                      or Supplemental Security Income (SSI).
                    </li>
                    <li>
                      Manual material on{" "}
                      <Link
                        className="medicaidlink"
                        to="https://www.hca.wa.gov/free-or-low-cost-health-care/i-help-others-apply-and-access-apple-health/applications-ltss"
                        target="__blank"
                      >
                        long-term care application processes
                      </Link>{" "}
                      .
                    </li>
                  </ul>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="medicaid-sec3">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720096619/section4-afh_ut8enj.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="medicaid-sec3 ">
                    <img
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720703692/providerimg2_rd1931.jpg"
                      alt=""
                      // style={{ height: "23rem" }}
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-6 col-12 medicaid-sec3-text">
                  <p className="mt-2">
                    There are many different types of homes or facilities where
                    a person can live and get care services in a residential
                    setting. One important consideration is whether the person
                    will be using state funds (Medicaid) to pay for care. If
                    state funds will be used, the home or facility must be
                    licensed by Washington State and accept Medicaid payment for
                    residents.
                  </p>
                  <p>Long-term residential care options include:</p>
                  <p>State licensed:</p>
                  <ul>
                    <li>Nursing Homes</li>
                    <li>Adult Family Homes</li>
                    <li>Assisted Living Facilities</li>
                  </ul>
                  <h3>Information Obtained From:</h3>
                  <Link
                    to="https://www.dshs.wa.gov/altsa/home-and-community-services/how-apply-medicaid"
                    target="__blank"
                  >
                    https://www.dshs.wa.gov/altsa/home-and-community-services/how-apply-medicaid
                  </Link>
                  <div
                    className="provider-btn mt-3 mb-5"
                    style={{ textAlign: "left" }}
                  >
                    <NavLink to="/contact-us" className="medicaidbtn ">
                      Contact Your Free Senior Placement Specialist
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Medicaidpage;
