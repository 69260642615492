import React from "react";
import "./DashBoardNavBar.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const DashBoardNavBar = () => {
  return (
    <>
      <Navbar expand="lg" className="dashboard-navbar">
        <Container fluid style={{ position: "relative", zIndex: "999" }}>
          <Navbar.Brand href="#home">
            <img
              src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720704397/afhlogo_zfiv9i.png"
              alt="dashboard-logo"
              width={110}
              className="navbar-brand-img"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <i className="fa-regular fa-bell dashboard-hell"></i>
              <div className="user-profile">
                <i className="fa-solid fa-circle-user dashboard-profile"></i>
                <span>Mugdha</span>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default DashBoardNavBar;
