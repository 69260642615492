import React from "react";
import CommonNavbar from "../../Pages/CommonNavbar";
import Footer from "../../Pages/Footer";
import "./Provider.css";
const Provider = () => {
  return (
    <div>
      <div className="families">
        <CommonNavbar />
        <div className="container mt-5">
          <div className="row">
            <div className="familybanner">
              <h1 style={{ paddingBottom: "5rem" }}>
                A FREE WAY TO MARKET YOUR AFH
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="provider-sec2">
        <div className="container">
          <div className="row mt-2">
            <div className="col-lg-6 col-md-12 col-12 ordertwo mt-2">
              <div className="provider-content mt-4">
                <p>
                  Since 2017, Adult Family Home Solutions LLC has focused
                  persistently on improving the experience for customers on
                  adultfamilyhomes.org and ensuring that our site is easy to use
                  and convenient for everyone who visits. We believe that in
                  doing so we can change an industry that has remained stagnant
                  for far too long and needs breakthrough technology and better
                  support.
                </p>

                <p>
                  Our team at AFH Solutions recognizes that the conventional
                  methods of finding senior care aren't enough to service
                  families' needs nor satisfy the type of search and information
                  that is desperately needed in such a delicate process.
                </p>

                <p>
                  We started listing adult family homes in Washington state in
                  2017 with a vision to make it easy for families to find the
                  right place for their loved ones. We have grown into a large
                  and strong community where each provider can easily market and
                  sell their home's services, amenities, and rooms using our
                  website. photos you provide is key in attracting a potential
                  client into your home
                </p>

                <p>
                  At AdultFamilyHomes.org, we work hard to ensure that every
                  listing is vetted, legitimate, and responsive to customers
                  searching for senior care. You, the provider, are critical to
                  the success of our company and we value every home that has
                  been and will be part of our journey to redefine how searching
                  and finding an Adult Family Homes happens.
                </p>

                <p>
                  With an influx of customers who require an increase in
                  assistance with placement, Adult Family Home Solutions has
                  established partnership with 3rd party advisors that will
                  assist placements of potential clients. Our goal is to
                  continue providing the best technology while at the same time
                  incorporating placement assistance for those that need more
                  help in finding the right senior care option.
                </p>

                <p>
                  We encourage licensed homeowners to register their Adult
                  Family Home, add lots of photos and start participating in the
                  fastest growing search tool in the industry while enjoying the
                  benefits of an easier and more convenient way to fill
                  vacancies, manage listings, and be present online! A potential
                  client is looking at your home right now, how will it stand
                  out compared to the other homes?
                </p>
              </div>
              <div className="provider-btn">
                <button>List Your Adult Family Home</button>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-5 orderone">
              <div className="row">
                <div className="col-lg-12">
                  <div className="provider-img1">
                    <img
                      style={{ width: "100%" }}
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720703692/providerimg_aikhfi.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5 displaynone ">
                <div className="col-lg-6">
                  <div className="provider-img2">
                    <img
                      style={{ width: "100%" }}
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720703692/providerimg3_gob1nw.jpg"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="provider-img1">
                    <img
                      style={{ width: "100%" }}
                      src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720703692/providerimg2_rd1931.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Provider;
