import React from "react";
// import CommonNavbar from '../Pages/CommonNavbar'
import LandingBanner from "../Pages/LandingBanner";
import Steps from "../Pages/Steps";
import Benefits from "../Pages/Benefits";
import Contactus from "../Pages/Contactus";
import Footer from "../Pages/Footer";
import Medicaid from "../Pages/Medicaid";
import "./MediaQuery/MediaQuery.css";

const Home = () => {
  return (
    <div>
      {/* <CommonNavbar/> */}
      <LandingBanner />
      <Steps />
      <Benefits />
      <Contactus />
      <Medicaid />
      <Footer />
    </div>
  );
};

export default Home;
