import React from "react";
import { NavLink } from "react-router-dom";

const Contactus = () => {
  return (
    <div>
      <div className="section-4">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-6 col-md-12 col-12 mb-5">
              <div className="section-4img" data-aos="zoom-in-right">
                <img
                  src="https://res.cloudinary.com/dbtsrjssc/image/upload/v1720096619/section4-afh_ut8enj.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-4text">
                <h1>Adult Family Homes Raise the Standard of Senior Living</h1>
                <p data-aos="fade-down-right" data-aos-duration="2000">
                  Thousands of people searching for senior care options in
                  Washington State choose Adult Family Homes each year for their
                  loved ones. <br /> <br />
                  Adult Family Homes are long-term senior care alternatives that
                  provide personalized and skilled healthcare for the elderly,
                  as well as room and board, for up to six senior residents.{" "}
                  <br />
                  <br />
                  Adult Family Homes raise the standard of senior living by
                  providing their elderly residents with a true 'home feel'.
                  <br /> <br />
                  Many elderly and their families experience a smooth transition
                  to long term care and express a deep gratitude knowing they
                  receive the highest in quality care because of this
                  personalized approach. The high staff to resident ratio
                  creates peace of mind for families who can trust their loved
                  ones are being diligently cared for at all times of the day.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* contactus-btn */}

        <div className="contactus-btn">
          <div className="overlay-contactus"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="contactus-heading">
                  <h1>Let Us Help You Find the Right Place</h1>
                </div>
                <div className="contactusbtn">
                  <NavLink to="/contact-us" className="medicaidbtn ">
                    Speak To One Of Our Free Senior Advisor's Today!
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
