import React from "react";

const Services_homeOwner = () => {
  const servies = [
    {
      key: "Private Room",
    },
    {
      key: "Room & Bath",
    },
    {
      key: "Room & Shower",
    },
    {
      key: "Semi-private Room",
    },
    {
      key: "Shared Main Shower",
    },
    {
      key: " Medication Delivery",
    },
    {
      key: " Stroke",
    },
    {
      key: "Female Residents Preferred",
    },
    {
      key: "24 hour care",
    },
    {
      key: " Medication Management",
    },
    {
      key: "Visual/Hearing Impaired",
    },
    {
      key: " Male Residentes Preferred",
    },
    {
      key: " Dementia/Alzheimer",
    },
    {
      key: "Oxygen Therapy",
    },
    {
      key: "Wound Care",
    },
    {
      key: " Medicaid Accepted",
    },
    {
      key: "Developmental Disabilities",
    },
    {
      key: "Parkinson's",
    },
    {
      key: "Doctor On Site",
    },
    {
      key: "Doctor On Site",
    },
    {
      key: "Home owner lives in the house & provides care",
    },
    {
      key: " Diabetes",
    },
    {
      key: "Pet Care",
    },
    {
      key: "Feeding Tube Resident's Welcomed",
    },
  ];
  return (
    <div style={{ padding: "20px" }}>
      <h1 className="mb-5">services</h1>
      <div className="row">
        {servies.map((data) => (
          <div className="col-lg-3 mb-3">
            <div className="services-menu">
              <input value={data.key} type="checkbox" />
              <span style={{ marginLeft: "10px" }}>{data.key}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="row mt-3">
        <div className="col-lg-6 col-md-6 col-12 text-end">
          <a href="" className="pre-btn">
            Pervious
          </a>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <a href="" className="pre-btn">
            Submit
          </a>
        </div>
      </div>
    </div>
  );
};

export default Services_homeOwner;
