import React, { useState } from "react";
import "./UserDashboardHomeOwner.css";
import Nav from "react-bootstrap/Nav";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Services_homeOwner from "./Services_homeOwner/Services_homeOwner";
import Amenities from "./Amenities/Amenities";
import HomeDescription from "./HomeDescription/HomeDescription";

const UserDashboardHomeOwner = () => {
  const [activeTab, setActiveTab] = useState("list-homes");
  const initialValues = {
    homeName: "",
    ownerName: "",
    homeAddress: "",
    contactNumber: "",
    city: "",
    zip: "",
    email: "",
    depositAmount: "",
  };
  const validationSchema = Yup.object({
    homeName: Yup.string().required("Home Name is required"),
    ownerName: Yup.string().required("Owner Name is required"),
    homeAddress: Yup.string().required("Home Address is required"),
    contactNumber: Yup.string().required("Contact Number is required"),
    city: Yup.string().required("City is required"),
    zip: Yup.string().required("ZipCode is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    depositAmount: Yup.string().required("Deposit Amount is required"),
  });
  const handleSubmit = (values) => {
    console.log(values);
  };
  return (
    <>
      <div className="user-dashboard-contact">
        <div className="contact-nav mt-5 mb-4">
          <Nav
            variant="tabs"
            activeKey={activeTab}
            onSelect={(selectedKey) => setActiveTab(selectedKey)}
          >
            <Nav.Item>
              <Nav.Link className="navs-items" eventKey="list-homes" href="">
                Lists Homes
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="navs-items" eventKey="service" href="">
                Services
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="navs-items" eventKey="amentiies" href="">
                Amenities &  Amenities
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className="navs-items" eventKey="description" href="">
                Home description & Photos
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        {activeTab === "list-homes" && (
          <div id="list-homes">
            <h1 className="lists-homes-heading">General Information</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values }) => (
                <Form>
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-12 mt-2">
                        <Field
                          placeholder="Home Name"
                          type="text"
                          name="homeName"
                          className="list-homes-form"
                        />
                        <ErrorMessage
                          name="homeName"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mt-2">
                        <Field
                          placeholder="Owner Name"
                          type="text"
                          name="ownerName"
                          className="list-homes-form"
                        />
                        <ErrorMessage
                          name="ownerName"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mt-4">
                        <Field
                          placeholder="Home Address"
                          type="text"
                          name="homeAddress"
                          className="list-homes-form"
                        />
                        <ErrorMessage
                          name="homeAddress"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mt-4">
                        <Field
                          placeholder="Contact Number"
                          type="number"
                          name="contactNumber"
                          className="list-homes-form"
                        />
                        <ErrorMessage
                          name="contactNumber"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mt-4">
                        <Field
                          placeholder="City"
                          type="text"
                          name="city"
                          className="list-homes-form"
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mt-4">
                        <Field
                          placeholder="Zip"
                          type="number"
                          name="zip"
                          className="list-homes-form"
                        />
                        <ErrorMessage
                          name="zip"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mt-4">
                        <Field
                          placeholder="Email"
                          type="email"
                          name="email"
                          className="list-homes-form"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="col-lg-12 col-md-12 col-12 mt-4">
                        <label className="Field-name">Deposit Amount : </label>
                        <Field
                          placeholder="Deposit Number"
                          type="number"
                          name="depositAmount"
                          className="deposit-btn"
                        />
                        <ErrorMessage
                          name="depositAmount"
                          component="div"
                          className="text-danger"
                        />
                      </div>
                      <div className="medicaid">
                        <span>
                          Medicaid:
                          <i class="fa-solid fa-circle-info medicaid-icon"></i>
                        </span>
                      </div>
                      <div className="medicaid-info">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-12">
                            <input type="checkbox" name="" id="" />
                            <label>Accepted upon Admission</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12">
                            <input type="checkbox" name="" id="" />
                            <label>
                              Accepted After 6 months of Private Pay
                            </label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12">
                            <input type="checkbox" name="" id="" />
                            <label>Accepted After 1 year of Private Pay</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12 mt-4">
                            <input type="checkbox" name="" id="" />
                            <label>Accepted After 2 year of Private Pay</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12 mt-4">
                            <input type="checkbox" name="" id="" />
                            <label>Accepted After 3 year of Private Pay</label>
                          </div>
                        </div>
                      </div>
                      <div className="medicaid ">
                        <span>
                          Private Pay Rates:
                          <i class="fa-solid fa-circle-info medicaid-icon"></i>
                        </span>
                      </div>
                      <div className="medicaid-info">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-12">
                            <input type="checkbox" name="" id="" />
                            <label>Independent Living</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12">
                            <input type="checkbox" name="" id="" />
                            <label>Stand By Assistance</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12">
                            <input type="checkbox" name="" id="" />
                            <label>Full Assistance</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12 mt-4">
                            <input type="checkbox" name="" id="" />
                            <label>Intensive Assistance</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12 mt-4">
                            <input type="checkbox" name="" id="" />
                            <label>Hospice End of Life Care </label>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-12 col-md-12 col-12 text-center mt-4">
                        <Link to="" className="specialist-btn">
                          Unlock Rates,Contact a Senior Placement Specialist
                        </Link>
                      </div> */}
                      <div className="medicaid ">
                        <span>
                          Select Room Category:
                          <i class="fa-solid fa-circle-info medicaid-icon"></i>
                        </span>
                      </div>
                      <div className="medicaid-info">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-12">
                            <input type="checkbox" name="" id="" />
                            <label>Private Room</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12">
                            <input type="checkbox" name="" id="" />
                            <label>Private Room</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12">
                            <input type="checkbox" name="" id="" />
                            <label>Room & Bath</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12 mt-4">
                            <input type="checkbox" name="" id="" />
                            <label>Room & Shower</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12 mt-4">
                            <input type="checkbox" name="" id="" />
                            <label>Semi-private Room</label>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12 mt-4">
                            <input type="checkbox" name="" id="" />
                            <label>Shared Main Shower</label>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-12 text-center mt-4 mb-4">
                          <Link to="" className="next-btn">
                            Next
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        {activeTab === "service" && (
          <div id="service">
            <Services_homeOwner />
          </div>
        )}
        {activeTab === "amentiies" && (
          <div id="amentiies">
            <Amenities />
          </div>
        )}
        {activeTab === "description" && (
          <div id="description">
            <HomeDescription />
          </div>
        )}
      </div>
    </>
  );
};

export default UserDashboardHomeOwner;
