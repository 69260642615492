import React from 'react'
import './OwnerProvider.css'

const OwnerProvider = () => {
  return (
    <div>
      <div className="provider">
        <h1>provider</h1>
        <form>
  <div className="row">
    <div className="col-lg-6">
    <input type="text" id="fname" name="fname" placeholder=' First Name' />
    </div>
    <div className="col-lg-6">
    <input type="text" id="fname" name="fname" placeholder=' Last Name' />
    </div>
  </div>
  <div className="row mt-4">
    <div className="col-lg-6">
    <input type="email" id="fname" name="fname" placeholder='Email Address'
    />
    </div>
    <div className="col-lg-6">
    <input type="number" id="fname" name="fname" placeholder='Contact Number' />
    </div>
  </div>


</form>

      </div>
    </div>
  )
}

export default OwnerProvider
