import React from 'react'
import './AdminContactUs.css';
const AdminContactUS = () => {
  const data=[
    {
      name:'Ekta Vaidya',
      date:'07/17/2024',
      time:'4:50 PM',
      EmailId:'santamariamilitza@yahoo.com',
      Phoneno:'8329254305',
      Subject:'Senior Placement Specialist',
      Question:'Good to GO!'
    },
    {
      name:'Ekta Vaidya',
      date:'07/17/2024',
      time:'4:50 PM',
      EmailId:'santamariamilitza@yahoo.com',
      Phoneno:'8329254305',
      Subject:'Senior Placement Specialist',
      Question:'Good to GO!'
    },
    {
      name:'Ekta Vaidya',
      date:'07/17/2024',
      time:'4:50 PM',
      EmailId:'santamariamilitza@yahoo.com',
      Phoneno:'8329254305',
      Subject:'Senior Placement Specialist',
      Question:'Good to GO!'
    },
  ]
  return (
    <div className='mt-4'>
      <h1 className='mt-2' style={{textAlign:'center'}}>Contact Us</h1>
      <div className="container">
        <div className="row">
          {data.map((state)=>(
            <div className="col-lg-6 user-contactus-div">
            <div className="user-contactus-data">
              <h6>Name: <span>{state.name}</span></h6>
              <h6>Date: <span>{state.date}</span></h6>
              <h6>Time: <span>{state.time}</span></h6>
              <h6>Email-Id: <span>{state.EmailId}</span></h6>
              <h6>Phone no: <span>{state.Phoneno}</span></h6>
              <h6>Subject: <span>{state.Subject}</span></h6>
              <h6>Question: <span>{state.Question}</span></h6>
            </div>
            <div className="user-contactus-btn">
              <div className="respond">
                <button>Respond</button>
              </div>
              <div className="delete">
                <button>Delete</button>
              </div>
              <div className="resolve">
                <button>Mark as Resolved?</button>
              </div>
            </div>
          </div>
          ))}
         
         
        </div>
      </div>
    </div>
  )
}

export default AdminContactUS
