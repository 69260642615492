import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./Admin.css";

const Admin = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-3 col-12">
            <div className="admin-sideNav">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button acc-btn"
                      data-bs-target="#collapseThree"
                      aria-controls="collapseThree"
                    >
                      <i class="fa-brands fa-get-pocket sideNav-icon"></i>
                      <NavLink to="/supernova" className="admin-dashboard">
                        DashBoard
                      </NavLink>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button acc-btn"
                      data-bs-target="#collapseThree"
                      aria-controls="collapseThree"
                    >
                      <i class="fa-solid fa-circle-user sideNav-icon"></i>
                      <NavLink
                        to="/supernova/contact-us"
                        className="admin-dashboard"
                      >
                        Contact us
                      </NavLink>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button acc-btn"
                      data-bs-target="#collapseThree"
                      aria-controls="collapseThree"
                    >
                      <i className="fa-solid fa-list-ul sideNav-icon"></i>
                      <NavLink
                        to="/supernova/all-listing"
                        className="admin-dashboard"
                      >
                        All Listing
                      </NavLink>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button acc-btn"
                      data-bs-target="#collapseFour"
                      aria-controls="collapseFour"
                    >
                      <i className="fa-solid fa-users sideNav-icon"></i>
                      <NavLink
                        to="/supernova/all-accounts"
                        className="admin-dashboard"
                      >
                        All Accounts
                      </NavLink>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed acc-cols-btn"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <i className="fa-solid fa-user-group sideNav-icon"></i>
                      <Link to="/leads" className="admin-dashboard">
                        Leads
                      </Link>
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse acc-btn"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul className="lead-dropdown">
                        <li>
                          <Link to="/send-leads" className="dropdown-item">
                            Send your Leads
                          </Link>
                        </li>
                        <li>
                          <Link to="/all-leads" className="dropdown-item">
                            All Leads
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button acc-btn"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-controls="collapseNine"
                    >
                      <i className="fa-solid fa-tablet-button sideNav-icon"></i>
                      <Link
                        to="/provider-application"
                        className="admin-dashboard"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Provider Application
                      </Link>
                    </button>
                  </h2>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed acc-cols-btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      <i className="fa-solid fa-rocket sideNav-icon"></i>
                      <Link
                        to="/seo"
                        className="admin-dashboard"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        SEO
                      </Link>
                    </button>
                  </h2>
                  <div
                    id="collapseTen"
                    className="accordion-collapse collapse acc-btn"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul className="lead-dropdown">
                        <li>
                          <Link to="/static-page" className="dropdown-item">
                            Static Page
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/city-static-page"
                            className="dropdown-item"
                          >
                            City Static Page
                          </Link>
                        </li>
                        <li>
                          <Link to="/sitemap" className="dropdown-item">
                            Sitemap
                          </Link>
                        </li>
                        <li>
                          <Link to="/cloudinary" className="dropdown-item">
                            Cloudinary
                          </Link>
                        </li>
                        <li>
                          <Link to="/blog" className="dropdown-item">
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link to="/event" className="dropdown-item">
                            Event
                          </Link>
                        </li>
                        <li>
                          <Link to="/video" className="dropdown-item">
                            Video
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/upload-dashboard-videos"
                            className="dropdown-item"
                            style={{ fontSize: "14px" }}
                          >
                            Upload DashBoard Videos
                          </Link>
                        </li>
                        <li>
                          <Link to="/gallery" className="dropdown-item">
                            Gallery
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-9 col-12"></div>
        </div>
      </div>
    </>
  );
};

export default Admin;
