import React from "react";
import "./OwnerDashboardPage.css";
import { Link } from "react-router-dom";

const OwnerDashboardPage = () => {
  return (
    <>
      <div style={{ height: "56rem" }}>
        {" "}
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-4">
                  <div className="home-listed mb-4 mt-4">
                    <span>
                      <i className="fa-solid fa-house-user"></i>No. of Homes
                      Listed
                    </span>
                    <p>01</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="total-leads mb-4 mt-4">
                    <span>
                      <i className="fa-solid fa-user-group"></i>Total No. of
                      Leads
                    </span>
                    <p>02</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="total-views mb-4 mt-4">
                    <span>
                      <i className="fa-solid fa-eye"></i>Total No. of Views
                    </span>
                    <p>03</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 text-center">
                  <div className="list-any-homes mb-4">
                    <p>You haven't listed any home</p>
                    <Link to="/owner/add-new-home" className="btn-list-home">
                      List New Home
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="dashboard-blog">
                <h2>Recent Blog</h2>
                <div className="recent-blog">
                  <img
                    src="https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1580823099/afh/blog/images/a0oiodwgk7imrzbnflgj.jpg"
                    alt=""
                  />
                  <h3>Technology for the Aged immensely for the elderly</h3>
                  <p>
                    We live in a technology-laden world. It is not a startling
                    fact that are overly dependent on technology . Imagine a day
                    witho
                  </p>
                  <Link to="" className="btn-recent-blog">
                    View More
                  </Link>
                </div>
                <div className="recent-blog mt-4">
                  <img
                    src="https://res.cloudinary.com/adultfamilyhomes-org/image/upload/v1580823099/afh/blog/images/a0oiodwgk7imrzbnflgj.jpg"
                    alt=""
                  />
                  <h3>Technology for the Aged immensely for the elderly</h3>
                  <p>
                    We live in a technology-laden world. It is not a startling
                    fact that are overly dependent on technology . Imagine a day
                    witho
                  </p>
                  <Link to="" className="btn-recent-blog">
                    View More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerDashboardPage;
